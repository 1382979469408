import React from 'react'

export const LoaderBase = (
	<div className="loader spacer npr">
		<div className="icon icon--loader" />
	</div>
)
const Loader = ({ height, text }) => {
	if (height && text) {
		return (
			<div
				style={{
					minHeight: height,
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{LoaderBase}
				<p className="typography">{text}</p>
			</div>
		)
	}
	if (height) {
		return <div style={{ minHeight: height }}>{LoaderBase}</div>
	}

	return LoaderBase
}
export { Loader }
